import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { Fade } from 'react-reveal'
import logo from '../images/epvp-logo.svg'

const Wrapper = styled.section`
  ${tw`w-full relative flex flex-col justify-center items-center`};
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23323d59' fill-opacity='0.4'%3E%3Cpath d='M0 38.59l2.83-2.83 1.41 1.41L1.41 40H0v-1.41zM0 1.4l2.83 2.83 1.41-1.41L1.41 0H0v1.41zM38.59 40l-2.83-2.83 1.41-1.41L40 38.59V40h-1.41zM40 1.41l-2.83 2.83-1.41-1.41L38.59 0H40v1.41zM20 18.6l2.83-2.83 1.41 1.41L21.41 20l2.83 2.83-1.41 1.41L20 21.41l-2.83 2.83-1.41-1.41L18.59 20l-2.83-2.83 1.41-1.41L20 18.59z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
    #273046;
  height: 75vh;
`

const Content = styled.div`
  ${tw`px-6 text-center`};
  top: -50px;
  position: relative;
`

const Logo = tw.img`w-48 md:w-64`

const Title = tw.h1`text-epvp-color font-sans text-4xl md:text-5xl mb-4 mt-0`

const SubTitle = tw.span`text-white font-sans font-thin mt-2 text-lg md:text-xl`

const Divider = styled.svg`
  ${tw`absolute text-gray-100 fill-current`};
  bottom: -1px;
`

const Hero = () => (
  <Wrapper>
    <Content>
      <Logo alt="elitepvpers" src={logo} />
      <Title>elitepvpers</Title>
      <Fade up>
        <SubTitle>play less, get more</SubTitle>
      </Fade>
    </Content>
    <Divider viewBox="0 0 1000 100">
      <path d="M 0 100 L 0 0 L 500 95 L 1000 0 L 1000 100 Z" />
    </Divider>
  </Wrapper>
)

export default Hero
