import * as React from 'react'
import tw, { styled } from 'twin.macro'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'
import 'typeface-open-sans'

import { Heading, SubHeading, Text, Quote, Highlight, Layout } from '../components'
import SEO from '../elements/SEO'
import Hero from '../elements/Hero'
import { maxWidth } from '../styles/width'

const TextLayout = styled.main`
  ${tw`pb-8 md:pb-16 mx-auto px-6`};
  max-width: ${maxWidth};
`

const ContainerWide = styled.div`
  ${tw`pt-12`};

  @media (min-width: 1200px) {
    margin-left: -15%;
    margin-right: -15%;
  }
`

const HeadingOne = ({ children }) => (
  <ContainerWide>
    <Heading>{children}</Heading>
  </ContainerWide>
)

const Index = ({
  data: {
    site: { siteMetadata },
    mdx: { body: content },
  },
}) => (
  <Layout>
    <SEO siteMetadata={siteMetadata} />
    <Hero />
    <MDXProvider
      components={{
        h2: HeadingOne,
        h3: SubHeading,
        p: Text,
        blockquote: Quote,
        strong: Highlight,
      }}
    >
      <TextLayout>
        <MDXRenderer>{content}</MDXRenderer>
      </TextLayout>
    </MDXProvider>
  </Layout>
)

export default Index

export const IndexQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        siteTitle
        siteUrl
        description
      }
    }
    mdx {
      body
    }
  }
`
