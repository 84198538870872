import * as React from 'react'
import { Helmet } from 'react-helmet'
import favicon from '../favicon.png'

const SEO = ({ siteMetadata }) => (
  <Helmet>
    <html lang="en" />
    <title>{siteMetadata.siteTitle}</title>
    <meta name="description" content={siteMetadata.description} />
    <meta name="image" content={favicon} />
    <meta name="robots" content="noindex" />
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
    <meta name="viewport" content="width=device-width, initial-scale=1" />
    <meta property="og:locale" content="en_US" />
    <meta property="og:site_name" content="elitepvpers" />
    <meta property="og:url" content={siteMetadata.siteUrl} />
    <meta property="og:title" content={siteMetadata.siteTitle} />
    <meta property="og:description" content={siteMetadata.description} />
    <meta property="og:image" content={favicon} />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:creator" content="@elitepvpers" />
    <meta name="twitter:title" content={siteMetadata.siteTitle} />
    <meta name="twitter:description" content={siteMetadata.description} />
    <meta name="twitter:image" content={favicon} />
  </Helmet>
)

export default SEO
